.about-img-container {
  display: flex;
  justify-content: center;
}
.about-img {
  max-width: 400px;
  max-width: 400px;
}
.li-lists {
  margin-top: 10px;
  font-size: 100%;
  font-weight: 600;
  text-decoration: darkorange;
}

.about-body {
  padding-top: 100px;
  overflow: hidden;
}
.lines {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.about-title {
  font-weight: 600;
  font-size: 300%;
}
.mini-title {
  font-weight: 600;
  font-size: 120%;
}
.about-mini-title {
  font-weight: 600;
  font-size: 250%;
}
