.home-body {
  width: 100vw;
  height: 100vh;
}
.home-text {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.home-inner {
  padding: 20px;
  border-radius: 5px;
  background:rgba(255,255,255,0.85);
}
.home-title {
  font-weight: 600;
  font-size: 300%;
  color: black;
}
.home-paragraph {
  font-weight: 600;
  font-size: 200%;
  color: black;
}
