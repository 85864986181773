.nav-link {
  text-decoration: none;
  font-weight: 500;
  font-weight: 600;
  color: white;
}
.nav-link:hover {
  color: #bab7b7;
}
.nav-logo:hover {
  color: #bab7b7;
}
.nav-link:focus {
  color: white;
}
.nav-link:active {
  color: #bab7b7;
}
.nav-logo {
  font-weight: 900;
  text-transform: uppercase;
  color: white;
}
.nav-link {
  text-transform: uppercase;
  font-weight: 400;
}
a:focus {
  color: #bab7b7;
}

.navbar,
.collapse,
.dropdown,
.nav-link,
.nav {
  overflow-x: visible;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translateY(0);
  transition: transform 0.6s ease;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  overflow-x: visible;
}
.languageIcon {
  cursor: pointer;
  margin-left: 10px;
  width: 40px;
}
@media (max-width: 768px) {
  .nav-logo {
    width: 70%;
  }
}
@media (max-width: 400px) {
  .nav-logo {
    width: 65%;
  }
}
