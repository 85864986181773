.services{
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-y: hidden;
}
.service-card{
    height: 200px;
}
.services-container{
    overflow: hidden;
}
