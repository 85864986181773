.container11 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 120px;
}
.memberInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profilePng {
  width: 50%;
  border-radius: 50%;
}
.personInfo {
  margin-top: 20px;
  width: "10vw";
  text-align: center;
}
