.contact-container {
  width: 100vw;
}
.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.logo {
  height: 150px;
}
.iconText {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: flex-start;
  color: white;
}
.icons {
  margin-right: 25px;
}
p {
  color: white;
}
.facebookLink {
  text-decoration: none;
}
