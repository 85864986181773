.container1 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  padding-top: 100px;
}
.line {
  width: 100px;
  height: 3px;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-top: 10px;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-top: 100px;
  padding-bottom: 100px;
}
.images {
  cursor: pointer;
  transition: transform 0.4s ease;
}
.images:hover {
  transform: translateY(-10px);
}
.headline {
  font-size: 50px;
}
.text {
  font-size: 30px;
}
/* Portfolio */
.portfolio-item {
  flex: 0 0 auto;
  padding: 0;
  width: 400px;
  height: 400px;
  overflow: hidden;
}
@media (max-width: 500px) {
  .portfolio-item {
    height: 300px;
  }
}
@media (min-width: 1600px) {
  .portfolio-item {
    height: 500px;
    width: 500px;
  }
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  height: 100%;
  margin: 5px;
  display: flex;
  justify-content: center;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(10, 44, 102, 0.8) 0%,
    rgba(3, 21, 51, 0.8) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}
.hover-bg img {
  cursor: pointer;
  object-fit: scale-down;
  transition: transform 0.4s ease;
  overflow: hidden;
}
.hover-bg img:hover {
  transform: translateY(100px);
  transition: transform 0.4s ease;
}